// libs
import { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// contexts
import { EthToolsContext } from "contexts/EthToolsContext";

// services
import { EthToolService } from "services/EthToolService";

// components
import { TxnTabs } from "./TxnTabs";

// utils
import {
  copyAddressToClipboard,
  formatEthAddress,
  formatISODate,
  formatTokens,
  formatUSD,
  showMarker,
} from "utils";

// assets
import EtherScan from "assets/EtherScan.svg";
import OpenLink from "assets/OpenLink.svg";

// constants
import { TABS, SERVICE_OPTIONS, SET_PAIR_ADDRESS } from "constants";

// styles
import styles from "./styles.module.scss";

export const TXN_TYPES = {
  buy: "Buy",
  sell: "Sell",
};

const txnColumns = [
  {
    title: "Type",
    dataIndex: "type",
    sorter: (a, b) => a.type?.localeCompare(b.type),
    render: (type) => {
      return (
        <span className={styles[`txnContainer${TXN_TYPES[type]}`]}>
          {TXN_TYPES[type]}
        </span>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date?.localeCompare(b.date),
    render: (date) => {
      return (
        <span className={styles.txnContainerDate}>{formatISODate(date)}</span>
      );
    },
  },
  {
    title: "Price USD",
    dataIndex: "usd",
    sorter: (a, b) => a.usd - b.usd,
    render: (usd) => <span>{formatUSD(usd, 3)}</span>,
  },
  {
    title: "Total",
    dataIndex: "value",
    sorter: (a, b) => a.value - b.value,
    render: (value) => <span>{formatTokens(value)}</span>,
  },
  {
    title: "Price ETH",
    dataIndex: "weth",
    sorter: (a, b) => a.weth - b.weth,
    render: (weth) => <span>{Number(weth).toPrecision(2)} ETH</span>,
  },
  {
    title: "Price",
    dataIndex: "token_price_against",
    sorter: (a, b) => a.token_price_against - b.token_price_against,
    render: (token_price_against) => (
      <span>{Number(token_price_against).toPrecision(2)}</span>
    ),
  },
  {
    title: "Maker",
    dataIndex: "maker",
    render: (maker, record) => (
      <div
        className={styles.txnContainerEthAddress}
        onClick={() => copyAddressToClipboard(maker)}
      >
        <img src={showMarker(record?.usd)} alt={maker} />{" "}
        <span>{formatEthAddress(maker)}</span>
      </div>
    ),
  },
  {
    title: "TX",
    dataIndex: "etherscan",
    align: "center",
    render: (_, record) => (
      <a
        href={`https://etherscan.io/address/${record.maker}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={EtherScan}
          alt={`Open EtherScan for address ${record.maker}`}
        />
      </a>
    ),
  },
];

const topHoldersColumns = [
  {
    title: "Address",
    dataIndex: "holder",
    render: (holder) => (
      <span
        className={styles.txnContainerEthAddress}
        onClick={() => copyAddressToClipboard(holder)}
      >
        {holder}
      </span>
    ),
  },
  {
    title: "%",
    dataIndex: "percentage",
    sorter: (a, b) => a.percentage - b.percentage,
    render: (percentage) => `${percentage}%`,
  },
  {
    title: "Balance",
    dataIndex: "tokens",
    sorter: (a, b) => a.tokens - b.tokens,
    render: (balance) => <span>{formatTokens(balance)}</span>,
  },
  {
    title: "Value",
    dataIndex: "value",
    sorter: (a, b) => a.value - b.value,
    render: (value) => <span>{formatUSD(value)}</span>,
  },
  {
    title: "EXP",
    dataIndex: "exp",
    align: "center",
    render: (_, record) => (
      <a
        href={`https://etherscan.io/address/${record.holder}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={OpenLink}
          alt={`Open EtherScan for address ${record.holder}`}
        />
      </a>
    ),
  },
];

const topLiquidityProvidersColumns = [
  {
    title: "Address",
    dataIndex: "holder",
    render: (address) => (
      <span
        className={styles.txnContainerEthAddress}
        onClick={() => copyAddressToClipboard(address)}
      >
        {address}
      </span>
    ),
  },
  {
    title: "%",
    dataIndex: "percentage",
    sorter: (a, b) => a.percentage - b.percentage,
    render: (percentage) => `${percentage}%`,
  },
  {
    title: "Balance",
    dataIndex: "tokens",
    sorter: (a, b) => a.tokens - b.tokens,
    render: (balance) => <span>{formatTokens(balance)}</span>,
  },
  {
    title: "EXP",
    dataIndex: "exp",
    align: "center",
    render: (_, record) => (
      <a
        href={`https://etherscan.io/address/${record.address}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={OpenLink}
          alt={`Open EtherScan for address ${record.address}`}
        />
      </a>
    ),
  },
];

export const TxnTable = () => {
  const { tokenAddress, tokenVersion, dispatch } = useContext(EthToolsContext);

  const { address } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(TABS.TRANSACTIONS);
  const [errorPollingRetry, setErrorPollingRetry] = useState(0);

  const isTokenV3 = tokenVersion === "v3";

  const isTransactionsTab = activeTab === TABS.TRANSACTIONS;
  const isHoldersTab = activeTab === TABS.HOLDERS;
  const isLiquidityProvidersTab =
    !isTokenV3 && activeTab === TABS.LIQUIDITY_PROVIDERS;

  const columns = isHoldersTab
    ? topHoldersColumns
    : isLiquidityProvidersTab
    ? topLiquidityProvidersColumns
    : txnColumns;

  const options = {
    ...SERVICE_OPTIONS,
    pairAddress: address,
    tokenAddress,
  };

  useEffect(() => {
    if (address) {
      dispatch({
        type: SET_PAIR_ADDRESS,
        payload: address,
      });
    }
  }, [address, dispatch, navigate]);

  const transactionQuery = useQuery(
    ["ethToolsTxns", activeTab],
    () => EthToolService.getTokenTransactions({ ...options }),
    {
      select: (data) => data?.data,
      staleTime: 15000,
      enabled: isTransactionsTab && !!options.pairAddress && !!tokenAddress,
      refetchInterval:
        errorPollingRetry <= 2 && isTransactionsTab ? 15000 : false,
      onError: (error) => {
        if (errorPollingRetry < 3) {
          setErrorPollingRetry((prev) => prev + 1);
        }
      },
    }
  );

  const holdersQuery = useQuery(
    ["ethToolsTxnsTopHolders"],
    () => EthToolService.getTokenTopHolders({ ...options }),
    {
      select: (data) => data?.data,
      staleTime: 300000,
      enabled: isHoldersTab && !!tokenAddress,
      refetchInterval: isHoldersTab ? 300000 : false,
    }
  );

  const liquidityQuery = useQuery(
    ["ethToolsTxnsLiquidityProviders"],
    () =>
      EthToolService.getTokenTopLiquidity({
        ...options,
        tokenVersion,
      }),
    {
      select: (data) => data?.data,
      staleTime: 300000,
      enabled:
        isLiquidityProvidersTab && !!options.pairAddress && !!tokenVersion,
      refetchInterval: isLiquidityProvidersTab ? 300000 : false,
    }
  );

  const isLoading =
    (isTransactionsTab && transactionQuery.isLoading) ||
    (isHoldersTab && holdersQuery.isLoading) ||
    (isLiquidityProvidersTab && liquidityQuery.isLoading);

  const data = isTransactionsTab
    ? transactionQuery.data
    : isHoldersTab
    ? holdersQuery.data
    : liquidityQuery.data;

  return (
    <div className={styles.txnContainer}>
      <TxnTabs
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        isTokenV3={isTokenV3}
      />
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: "max-content",
        }}
        pagination
        loading={isLoading}
      />
    </div>
  );
};
