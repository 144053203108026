// libs
import React from "react";
import clsx from "classnames";

// styles
import styles from "../styles.module.scss";

export const Tab = ({ id, tab, icon, activeTab, onClick }) => {
  return (
    <div
      className={styles.txnContainerHeaderItem}
      onClick={() => onClick(tab)}
      key={id}
    >
      <img src={icon} alt={`EthTools ${tab}`} />
      <h5
        className={clsx({
          [styles.active]: activeTab === tab,
        })}
      >
        {tab}
      </h5>
    </div>
  );
};
