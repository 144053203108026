// libs
import { useContext, useState } from "react";
import { Col, Divider, Row, Space } from "antd";
import { useMutation, useQueries } from "react-query";

// contexts
import { EthToolsContext } from "contexts/EthToolsContext";

// services
import { EthToolService } from "services/EthToolService";

// components
import { PairContent } from "./PairContent";
import { WolfyScore } from "./WolfyScore";
import { WolfyLinks } from "./WolfyLinks";
import { WolfyReaction } from "./WolfyReaction";

// constants
import {
  SERVICE_OPTIONS,
  SET_TOKEN_ADDRESS,
  SET_INVALID_PAIR,
  SET_TOKEN_VERSION,
} from "constants";

// utils
import { formatUSD, formatWEth, showReactionNotification } from "utils";

// assets
import ActionNeededIcon from "assets/ActionNeeded.svg";
import EditIcon from "assets/Edit.svg";
import LogoIcon from "assets/Logo.svg";
import EthereumIcon from "assets/Ethereum.svg";
import UniswapIcon from "assets/Uniswap.svg";
import WebIcon from "assets/Web.svg";
import TwitterIcon from "assets/Twitter.svg";
import TelegramIcon from "assets/Telegram.svg";
import RightArrowSmallIcon from "assets/RightArrowSmall.svg";

// styles
import styles from "./styles.module.scss";

export const ActionSidebar = () => {
  const { pairAddress, tokenAddress, dispatch } = useContext(EthToolsContext);

  const [reactions, setReactions] = useState({
    rocket: 0,
    fire: 0,
    shit: 0,
    flag: 0,
  });
  const [selectedReaction, setSetSelectedReaction] = useState("");

  const options = {
    ...SERVICE_OPTIONS,
    pairAddress,
    tokenAddress,
  };

  const [{ data: ethToolsTokenInfo }, { data: ethToolsTokenSecurity }] =
    useQueries([
      {
        queryKey: `ethToolsTokenInfo`,
        queryFn: () => EthToolService.getTokenInfo({ ...options }),
        select: (data) => data?.data,
        staleTime: 300000,
        enabled: !!pairAddress,
        onSuccess: (data) => {
          if (!data) {
            dispatch({
              type: SET_INVALID_PAIR,
              payload: true,
            });
            return;
          }
          dispatch({
            type: SET_TOKEN_ADDRESS,
            payload: data?.baseToken?.address,
          });
          dispatch({
            type: SET_TOKEN_VERSION,
            payload: data?.labels[0],
          });
        },
        onError: (error) => {
          dispatch({
            type: SET_INVALID_PAIR,
            payload: true,
          });
        },
      },
      {
        queryKey: `ethToolsTokenSecurity`,
        queryFn: () => EthToolService.getTokenSecurity({ ...options }),
        select: (data) => data?.data,
        staleTime: 300000,
        enabled: !!tokenAddress,
      },
      {
        queryKey: `ethToolsTokenReactions`,
        queryFn: () => EthToolService.getTokenReactions({ ...options }),
        select: (data) => data?.data,
        staleTime: 300000,
        enabled: !!tokenAddress,
        onSuccess: (data) => setReactions(data),
      },
    ]);

  const {
    mutateAsync: ethToolsTokenLikeMutate,
    isLoading: isEthToolsTokenReactionsLoading,
  } = useMutation(
    ["ethToolsTokenLike"],
    (likedItem) => EthToolService.sendTokenLike({ ...options, likedItem }),
    {
      select: (data) => data?.data,
    }
  );

  const handleTokenReaction = async (likedItem) => {
    try {
      setSetSelectedReaction(likedItem);
      const res = await ethToolsTokenLikeMutate(likedItem);
      setReactions((prev) => {
        return {
          ...prev,
          ...(res && res?.data),
          updated: true,
        };
      });
      showReactionNotification(likedItem);
    } catch (error) {}
  };

  const renderTokenInfoCard = () => {
    const baseToken = ethToolsTokenInfo?.baseToken;
    const quoteToken = ethToolsTokenInfo?.quoteToken;

    return (
      <Space className={styles.actionContainerPriceHeaderText}>
        <p>
          {baseToken?.symbol} <span>/ {quoteToken?.symbol}</span>
        </p>
      </Space>
    );
  };

  const renderHighlights = () => {
    const liquidity = ethToolsTokenInfo?.liquidity;

    return (
      <div className={styles.actionContainerHighlights}>
        <div className={styles.actionContainerHighlightsContent}>
          <h5>Liquidity</h5>
          <p>{formatUSD(liquidity?.usd)}</p>
        </div>
        <div className={styles.actionContainerHighlightsContent}>
          <h5>FDV</h5>
          <p>{formatUSD(ethToolsTokenInfo?.fdv)}</p>
        </div>
        <div className={styles.actionContainerHighlightsContent}>
          <h5>MKT Cap</h5>
          <p>{formatUSD(ethToolsTokenInfo?.fdv)}</p>
        </div>
      </div>
    );
  };

  const renderPriceChange = () => {
    return (
      <div className={styles.actionContainerTimeframes}>
        <div className={styles.actionContainerTimeframesContent}>
          <h5>5M</h5>
          <PriceChangeItem item={ethToolsTokenInfo?.priceChange?.m5} />
        </div>
        <Divider type="vertical" />
        <div className={styles.actionContainerTimeframesContent}>
          <h5>1H</h5>
          <PriceChangeItem item={ethToolsTokenInfo?.priceChange?.h1} />
        </div>
        <Divider type="vertical" />
        <div className={styles.actionContainerTimeframesContent}>
          <h5>6H</h5>
          <PriceChangeItem item={ethToolsTokenInfo?.priceChange?.h6} />
        </div>
        <Divider type="vertical" />
        <div className={styles.actionContainerTimeframesContent}>
          <h5>24H</h5>
          <PriceChangeItem item={ethToolsTokenInfo?.priceChange?.h24} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.actionContainer}>
      <div className={styles.actionContainerPriceHeader}>
        {renderTokenInfoCard()}
        <Row className={styles.actionContainerPriceHeaderCompare}>
          <Col lg={10}>
            <h3>Price USD</h3>
            <p>
              {ethToolsTokenInfo?.priceUsd
                ? formatUSD(ethToolsTokenInfo?.priceUsd, 6)
                : null}
            </p>
          </Col>
          <Col lg={14}>
            <h3>Price</h3>
            <p>
              {ethToolsTokenInfo?.priceNative
                ? `${formatWEth(ethToolsTokenInfo?.priceNative)} WETH`
                : null}
            </p>
          </Col>
        </Row>
        <div className={styles.actionContainerPriceHeaderSwap}>
          <Space>
            <img src={EthereumIcon} alt={ethToolsTokenInfo?.chainId} />
            <p>{ethToolsTokenInfo?.chainId}</p>
          </Space>
          <img src={RightArrowSmallIcon} alt="EthTools" />
          <Space>
            <img src={UniswapIcon} alt={ethToolsTokenInfo?.dexId} />
            <p className="capitalize">
              {ethToolsTokenInfo?.dexId}{" "}
              <span>{ethToolsTokenInfo?.labels[0]}</span>
            </p>
          </Space>
        </div>
      </div>
      {renderHighlights()}
      {renderPriceChange()}
      <PairContent
        pairCreatedAt={ethToolsTokenInfo?.pairCreatedAt}
        liquidity={ethToolsTokenInfo?.liquidity}
        pairAddress={ethToolsTokenInfo?.pairAddress}
        ethToolsAddress={ethToolsTokenInfo?.baseToken?.address}
        wethAddress={ethToolsTokenInfo?.quoteToken?.address}
        url={ethToolsTokenInfo?.url}
        pairTokenName={ethToolsTokenInfo?.baseToken?.symbol}
        quoteTokenName={ethToolsTokenInfo?.quoteToken?.symbol}
      />
      <WolfyScore ethToolsTokenSecurity={ethToolsTokenSecurity} />
      <WolfyLinks ethToolsTokenSecurity={ethToolsTokenSecurity} />
      <div className={styles.actionContainerReflect}>
        <WolfyReaction
          reaction="rocket"
          count={reactions?.rocket}
          onClick={() => handleTokenReaction("rocket")}
          loading={
            selectedReaction === "rocket" && isEthToolsTokenReactionsLoading
          }
        />
        <WolfyReaction
          reaction="fire"
          count={reactions?.fire}
          onClick={() => handleTokenReaction("fire")}
          loading={
            selectedReaction === "fire" && isEthToolsTokenReactionsLoading
          }
        />
        <WolfyReaction
          reaction="shit"
          count={reactions?.shit}
          onClick={() => handleTokenReaction("shit")}
          loading={
            selectedReaction === "shit" && isEthToolsTokenReactionsLoading
          }
        />
        <WolfyReaction
          reaction="flag"
          count={reactions?.flag}
          onClick={() => handleTokenReaction("flag")}
          loading={
            selectedReaction === "flag" && isEthToolsTokenReactionsLoading
          }
        />
      </div>
      {reactions?.updated ? (
        <>
          <div className={styles.actionContainerInfo}>
            {reactions?.socials?.image && <img src={LogoIcon} alt="EthTools" />}
            {reactions?.socials?.description && (
              <p>{reactions?.socials?.description}</p>
            )}
          </div>
          <div className={styles.actionContainerFooter}>
            {reactions?.socials?.website && (
              <a
                href={reactions?.socials?.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={WebIcon} alt={reactions?.socials?.website} />
                {reactions?.socials?.website}
              </a>
            )}
            {reactions?.socials?.twitter && (
              <a
                href={reactions?.socials?.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TwitterIcon} alt="Twitter" />
                Twitter
              </a>
            )}
            {reactions?.socials?.telegram && (
              <a
                href={reactions?.socials?.telegram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TelegramIcon} alt="Telegram" />
                Telegram
              </a>
            )}
          </div>
        </>
      ) : (
        <div className={styles.actionContainerAction}>
          <img src={ActionNeededIcon} alt="Update token info" />
          <p>
            Additional token info not available. <br />
            Update Token Info
          </p>
        </div>
      )}
      <div className={styles.actionContainerAction}>
        <a
          href="https://t.me/ethtools_v1_bot"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={EditIcon} alt="Update token info" />
          Update token info
        </a>
      </div>
    </div>
  );
};

export const PriceChangeItem = ({ item }) => {
  if (!item) return <p>0%</p>;

  const isPositive = item >= 0;

  return <p className={isPositive ? "positive" : "negative"}>{item}%</p>;
};
