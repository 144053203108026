// libs
import React, { useEffect, useState } from "react";

// components
import { Tab } from "./Tab";

// constants
import { TABS } from "constants";

// assets
import TxnHeaderIcon from "assets/Table.svg";
import TxnHolderIcon from "assets/Holders.svg";
import TxnLiquidityProviderIcon from "assets/Liquidity Providers.svg";

import TxnHeaderActiveIcon from "assets/active/Table.svg";
import TxnHolderActiveIcon from "assets/active/Holders.svg";
import TxnLiquidityProviderActiveIcon from "assets/active/Liquidity Providers.svg";

import DarkTxnHeaderActiveIcon from "assets/active/dark-theme/Table.svg";
import DarkTxnHolderActiveIcon from "assets/active/dark-theme/Holders.svg";
import DarkTxnLiquidityProviderActiveIcon from "assets/active/dark-theme/Liquidity Providers.svg";

// styles
import styles from "./styles.module.scss";
import { useTheme } from "contexts/ThemeContext";

const TABS_ICON_MAP = {
  default: {
    [TABS.TRANSACTIONS]: TxnHeaderIcon,
    [TABS.HOLDERS]: TxnHolderIcon,
    [TABS.LIQUIDITY_PROVIDERS]: TxnLiquidityProviderIcon,
  },
  active: {
    [TABS.TRANSACTIONS]: TxnHeaderActiveIcon,
    [TABS.HOLDERS]: TxnHolderActiveIcon,
    [TABS.LIQUIDITY_PROVIDERS]: TxnLiquidityProviderActiveIcon,
  },
  theme: {
    [TABS.TRANSACTIONS]: DarkTxnHeaderActiveIcon,
    [TABS.HOLDERS]: DarkTxnHolderActiveIcon,
    [TABS.LIQUIDITY_PROVIDERS]: DarkTxnLiquidityProviderActiveIcon,
  },
};

export const TxnTabs = ({ activeTab, setActiveTab, isTokenV3 }) => {
  const { theme } = useTheme();

  const [tabs, setTabs] = useState({
    TRANSACTIONS: "Transactions",
    HOLDERS: "Holders",
  });

  useEffect(() => {
    if (isTokenV3) return;

    setTabs((prev) => ({
      ...prev,
      LIQUIDITY_PROVIDERS: "Liquidity Providers",
    }));
  }, [isTokenV3]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const allTabs = Object.values(tabs);

  return (
    <div className={styles.txnContainerHeader}>
      {allTabs.map((tab, index) => {
        const icon =
          activeTab === tab
            ? theme
              ? TABS_ICON_MAP.theme[tab]
              : TABS_ICON_MAP.active[tab]
            : TABS_ICON_MAP.default[tab];
        return (
          <Tab
            id={index}
            tab={tab}
            icon={icon}
            activeTab={activeTab}
            onClick={handleTabClick}
          />
        );
      })}
    </div>
  );
};
