// components
import { Result } from "./Result";

// styles
import styles from "./styles.module.scss";

export const SearchResults = ({ data }) => {
  if (!data || data?.length === 0)
    return (
      <div className={styles.containerNotFound}>
        <p>No results found, please try again!</p>
      </div>
    );

  return (
    <div className={styles.container}>
      {data.map((pair) => {
        return (
          <Result
            pairAddress={pair?.pairAddress}
            baseToken={pair?.baseToken}
            quoteToken={pair?.quoteToken}
            price={pair?.priceUsd}
            liquidity={pair?.liquidity.usd}
            volume={pair?.volume?.h24}
            priceChange={pair?.priceChange?.h24}
          />
        );
      })}
    </div>
  );
};
