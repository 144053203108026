// libs
import { Fragment } from "react";
import Marquee from "react-fast-marquee";

// components
import { Item } from "./Item";

export const InfiniteScroller = ({
  items,
  speed = 15,
  direction = "right",
}) => {
  return (
    <Marquee direction={direction} gradient={false} speed={speed}>
      {items?.length > 0 &&
        items.map((item) => (
          <Fragment key={item?.pairAddress}>
            <Item
              icon={item?.image_url}
              price={item?.priceUsd}
              title={item?.symbol}
              address={item?.pairAddress}
            />
          </Fragment>
        ))}
    </Marquee>
  );
};
