// libs
import { Button, Space } from "antd";

// assets
import OpenLink from "assets/OpenLink.svg";
import CopyAddress from "assets/Copy.svg";
import Twitter from "assets/Twitter.svg";
import Search from "assets/Search.svg";

// utils
import {
  copyAddressToClipboard,
  formatEthAddress,
  formatTimestamp,
  formatUSD,
  numberWithCommas,
} from "utils";

// styles
import styles from "./styles.module.scss";

export const PairContent = ({
  pairCreatedAt,
  liquidity,
  pairAddress,
  ethToolsAddress,
  wethAddress,
  url,
  pairTokenName,
  quoteTokenName,
}) => {
  const copyAddressOnClick = (address) => copyAddressToClipboard(address);

  return (
    <div className={styles.pairContainer}>
      <div className={styles.pairContainerContent}>
        <h6>Pair created</h6>
        <p>
          <span>
            {pairCreatedAt && <p>{formatTimestamp(pairCreatedAt)}</p>}
          </span>
        </p>
      </div>
      <div className={styles.pairContainerContent}>
        <h6>Pooled {pairTokenName}</h6>
        <p>
          <span>
            {liquidity?.base && <p>{numberWithCommas(liquidity.base)}</p>}
          </span>
          {liquidity?.usd ? formatUSD(liquidity?.usd / 2) : 0}
        </p>
      </div>
      <div className={styles.pairContainerContent}>
        <h6>Pooled {quoteTokenName}</h6>
        <p>
          <span>
            {liquidity?.quote && <p>{numberWithCommas(liquidity.quote)}</p>}
          </span>
          {liquidity?.usd ? formatUSD(liquidity?.usd / 2) : 0}
        </p>
      </div>
      <div className={styles.pairContainerRedirects}>
        <div className={styles.pairContainerRedirectsItem}>
          <h6>Pair</h6>
          <Space>
            <p>
              LPs
              <img src={OpenLink} alt="LPs" />
            </p>
            <p>
              EXP
              <img src={OpenLink} alt="EXP" />
            </p>
            <Button
              className="copy"
              onClick={() => copyAddressOnClick(pairAddress)}
            >
              {formatEthAddress(pairAddress)}
              <img src={CopyAddress} alt="" />
            </Button>
          </Space>
        </div>
        <div className={styles.pairContainerRedirectsItem}>
          <h6>{pairTokenName}</h6>
          <Space>
            <p>
              LPs
              <img src={OpenLink} alt="LPs" />
            </p>
            <p>
              EXP
              <img src={OpenLink} alt="EXP" />
            </p>
            <Button
              className="copy"
              onClick={() => copyAddressOnClick(ethToolsAddress)}
            >
              {formatEthAddress(ethToolsAddress)}
              <img src={CopyAddress} alt="" />
            </Button>
          </Space>
        </div>
        <div className={styles.pairContainerRedirectsItem}>
          <h6>{quoteTokenName}</h6>
          <Space>
            <p>
              LPs
              <img src={OpenLink} alt="LPs" />
            </p>
            <p>
              EXP
              <img src={OpenLink} alt="EXP" />
            </p>
            <Button
              className="copy"
              onClick={() => copyAddressOnClick(wethAddress)}
            >
              {formatEthAddress(wethAddress)}
              <img src={CopyAddress} alt="" />
            </Button>
          </Space>
        </div>
      </div>
      <div className={styles.pairContainerFindPairs}>
        <a
          href={`https://twitter.com/search?q=WETH`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={Twitter} alt="Search on Twitter" />
          Search on Twitter
        </a>
        <a href={url} target="_blank" rel="noreferrer noopener">
          <img src={Search} alt="Search Other pair" />
          Other pair
        </a>
      </div>
    </div>
  );
};
