// libs
import React, { createContext, useContext, useEffect, useState } from "react";

// utils
import { applyThemeColors } from "utils/themes";

// constants
import { LOCAL_STORAGE_THEME_KEY, THEMES } from "constants/themes";

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const storedTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
  const [theme, setTheme] = useState(storedTheme ? storedTheme : THEMES.DARK);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT
    );
  };

  useEffect(() => {
    if (storedTheme) return;

    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

    setTheme(darkModeQuery.matches ? THEMES.DARK : THEMES.LIGHT);

    const handleDarkModeChange = (e) => {
      setTheme(e.matches ? THEMES.DARK : THEMES.LIGHT);
    };

    darkModeQuery.addListener(handleDarkModeChange);

    return () => {
      darkModeQuery.removeListener(handleDarkModeChange);
    };
  }, [storedTheme]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, theme);

    applyThemeColors(theme);
  }, [theme]);

  const themeValues = {
    theme,
    toggleTheme,
  };

  return (
    <ThemeContext.Provider value={themeValues}>
      {children}
    </ThemeContext.Provider>
  );
};
