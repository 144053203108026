// libs
import { notification } from "antd";

// assets
import RocketIcon from "assets/Rocket.svg";
import FireIcon from "assets/Fire.svg";
import PooIcon from "assets/Poo.svg";
import FlagIcon from "assets/Flag.svg";

import FishIcon from "assets/markers/fish.svg";
import DolphinIcon from "assets/markers/dolphin.svg";
import ShirmpIcon from "assets/markers/shirmp.svg";
import WhaleIcon from "assets/markers/whale.svg";

export const formatEthAddress = (address) => {
  if (!address) return;
  return `${address.slice(0, 5)}...${address.slice(-4)}`;
};

export const formatISODate = (inputISODate) => {
  if (!inputISODate) return;
  const inputDate = new Date(inputISODate);

  if (isNaN(inputDate.getTime())) {
    return null;
  }

  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
  return formatter.format(inputDate);
};

export const copyAddressToClipboard = (address) => {
  if (!address || !navigator.clipboard) return;
  navigator.clipboard.writeText(address);
  notification.success({
    message: "Address copied to clipboard",
    duration: 2,
    placement: "bottom",
  });
};

export const formatUSD = (number, maxDigits = 2) => {
  const formattedNumber = Number(number);

  return formattedNumber?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: maxDigits,
    maximumFractionDigits: maxDigits,
    notation: "compact",
    compactDisplay: "short",
  });
};

export const formatTokens = (number) => {
  const formattedNumber = Number(number);

  return formattedNumber?.toLocaleString("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: "compact",
    compactDisplay: "short",
  });
};

export const formatWEth = (weth) => {
  if (!weth) return;
  const formattedWeth = Number(weth);

  return formattedWeth.toFixed(9);
};

export const formatTimestamp = (timestamp) => {
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - timestamp;

  const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)
  );
  const days = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
  );
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  let formattedString = "";

  if (years > 0) {
    formattedString += `${years}y `;
  }

  if (months > 0) {
    formattedString += `${months}mo `;
  }

  if (days > 0) {
    formattedString += `${days}d `;
  }

  if (hours > 0) {
    formattedString += `${hours}h `;
  }

  if (formattedString === "") {
    formattedString = "Just now";
  } else {
    formattedString += "ago";
  }

  return formattedString;
};

export const numberWithCommas = (number) => {
  if (typeof number !== "number") {
    return number;
  }

  return number.toLocaleString();
};

export const reactionIconsMapper = {
  rocket: RocketIcon,
  fire: FireIcon,
  shit: PooIcon,
  flag: FlagIcon,
};

export const markerIconsMapper = {
  fish: FishIcon,
  dolphin: DolphinIcon,
  shirmp: ShirmpIcon,
  whale: WhaleIcon,
};

export const showReactionNotification = (likedItem) => {
  let message;
  switch (likedItem) {
    case "rocket":
      message = "You launched to the moon!";
      break;
    case "fire":
      message = "Hot stuff! Your reaction is on fire";
      break;
    case "shit":
      message = "Oops! That reaction stinks";
      break;
    case "flag":
      message = "Flagged! Your attention is noted";
      break;
    default:
      message = "You reacted!";
  }

  notification.success({
    message,
    duration: 3,
    placement: "bottom",
    icon: <img src={reactionIconsMapper[likedItem]} alt={likedItem} />,
  });
};

export const showMarker = (price) => {
  if (price <= 250) {
    return markerIconsMapper.fish;
  } else if (price >= 1000 && price <= 10000) {
    return markerIconsMapper.dolphin;
  } else if (price >= 250 && price <= 1000) {
    return markerIconsMapper.shirmp;
  } else {
    return markerIconsMapper.whale;
  }
};
