// libs
import { useState, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Layout, Skeleton } from "antd";

// services
import { EthToolService } from "services/EthToolService";

// contexts
import { EthToolsContext } from "contexts/EthToolsContext";
import { useTheme } from "contexts/ThemeContext";

// pages
import { NotFound } from "pages/NotFound";

// components
import { InfiniteScroller } from "components/InfiniteScroller";
import { TxnTable } from "components/TxnTable";
import { SearchModal } from "components/SearchModal";
import { Navbar } from "components/Navbar";
import { ActionSidebar } from "components/ActionSidebar";

// assets
import HotPairsIcon from "assets/HotPairs.svg";
import TokenNotFoundIcon from "assets/TokenNotFound.svg";

// constants
import {
  TOGGLE_SEARCH_MODAL,
  SET_HAS_PAIR_ADDRESS_UPDATED,
  SET_PAIR_ADDRESS,
  TOKEN_TYPES,
  DEFAULT_PAIR_ADDRESS,
} from "constants";
import { THEMES } from "constants/themes";

// styles
import styles from "./styles.module.scss";

const { Content, Sider } = Layout;

export const Home = () => {
  const { address } = useParams();

  const { theme } = useTheme();
  const storedTheme = theme === THEMES.DARK ? THEMES.DARK : THEMES.LIGHT;

  const { searchModal, isInValidPair, dispatch } = useContext(EthToolsContext);
  const [query, setQuery] = useState("");
  const [searchTokenQuery, setSearchTokenQuery] = useState("");

  const { data: trendingTokenResults, isLoading: isTrendingTokenLoading } =
    useQuery(["ethToolsTrendingTokens"], EthToolService.getTrendngTokens, {
      select: (data) => data?.data,
      staleTime: 30000,
    });

  const { data: searchTokenResults, isLoading: isSearchTokenLoading } =
    useQuery(
      ["ethToolsTxns", searchTokenQuery],
      () =>
        EthToolService.getTokenSearch({
          pairAddress: address,
          query: searchTokenQuery,
        }),
      {
        select: (data) => data?.data,
        staleTime: 15000,
        enabled: !!address && !!searchTokenQuery,
      }
    );

  const handleSearchInputOnChange = (e) => {
    const value = e.target.value.trim();
    setQuery(value);
    if (value.length < 3) return;
    setSearchTokenQuery(value);
  };

  const handleSearchCancel = (pairAddress) => {
    dispatch({ type: TOGGLE_SEARCH_MODAL });
    dispatch({ type: SET_HAS_PAIR_ADDRESS_UPDATED });
    dispatch({
      type: SET_PAIR_ADDRESS,
      payload: pairAddress,
    });
  };

  const searchTokenResultsData = useMemo(() => {
    if (query.length < 3 || query !== searchTokenQuery) return [];

    return searchTokenResults;
  }, [query, searchTokenQuery, searchTokenResults]);

  const renderContent = () => {
    if (isInValidPair) {
      return (
        <NotFound
          errorImg={TokenNotFoundIcon}
          errorMsg="INVALID TOKEN ADDRESS"
          title="Token not found"
          desc="Please check the token address and try again."
          redirectPath={`/${TOKEN_TYPES.ETH}/${DEFAULT_PAIR_ADDRESS}`}
        />
      );
    }

    return (
      <>
        <div className={styles.iframeContainer}>
          <iframe
            id="dextools-widget"
            title="DEXTools Trading Chart"
            src={`https://www.dextools.io/widget-chart/en/ether/pe-light/${address}?theme=${storedTheme}&chartType=2&chartResolution=30&drawingToolbars=false`}
          />
        </div>
        <TxnTable />
        <SearchModal
          query={query}
          searchTokenResults={searchTokenResultsData}
          isSearchTokenLoading={isSearchTokenLoading}
          isModalOpen={searchModal}
          onSearchInputChange={handleSearchInputOnChange}
          onCancel={handleSearchCancel}
          latestUpdatesData={trendingTokenResults}
        />
      </>
    );
  };

  return (
    <Layout>
      <Content>
        <Navbar />
        <div className={styles.hotPairsContainer}>
          <img src={HotPairsIcon} alt="EthTools Hot Pairs" />
          {isTrendingTokenLoading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <InfiniteScroller items={trendingTokenResults} speed={25} />
          )}
        </div>
        {renderContent()}
      </Content>
      {!isInValidPair && (
        <Sider>
          <ActionSidebar />
        </Sider>
      )}
    </Layout>
  );
};
