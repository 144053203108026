// libs
import { Progress, Space } from "antd";

// assets
import SheildCheck from "assets/SheildCheck.svg";
import SheildUnCheck from "assets/SheildUnCheck.svg";
import Checked from "assets/Checked.svg";
import NotVerified from "assets/NotVerified.svg";

// styles
import styles from "./styles.module.scss";

export const WolfyScore = ({ ethToolsTokenSecurity }) => {
  const renderProgressText = () => {
    return (
      <Space direction="vertical" className={styles.wolfyScoreProgress}>
        <h1>{ethToolsTokenSecurity?.score || 0}</h1>
        <p>Out of 99</p>
      </Space>
    );
  };

  return (
    <div className={styles.wolfyScore}>
      <Space direction="vertical" align="center">
        <h6>Wolfy Score</h6>
        <Progress
          type="circle"
          percent={ethToolsTokenSecurity?.score || 0}
          format={renderProgressText}
          strokeColor={{
            "30%": "#3861FB",
            "70%": "#5196FF",
          }}
        />
      </Space>
      <div className={styles.wolfyScoreContainer}>
        <div className={styles.wolfyScoreContainerItem}>
          {ethToolsTokenSecurity?.verified ? (
            <img src={SheildCheck} alt="Contract Verified" />
          ) : (
            <img src={SheildUnCheck} alt="Contract Not Verified" />
          )}
          <p>Contract Verified</p>
        </div>
        <div className={styles.wolfyScoreContainerItem}>
          {ethToolsTokenSecurity?.honeypot ? (
            <img src={Checked} alt="Sell Tax" />
          ) : (
            <img src={NotVerified} alt="Honeypot Not Verified" />
          )}
          <p>Honeypot</p>
        </div>
        <div className={styles.wolfyScoreContainerItem}>
          <h5>{ethToolsTokenSecurity?.buy_tax || 0}%</h5>
          <p>Buy Tax</p>
        </div>
        <div className={styles.wolfyScoreContainerItem}>
          <h5>{ethToolsTokenSecurity?.sell_tax || 0}%</h5>
          <p>Sell Tax</p>
        </div>
      </div>
    </div>
  );
};
