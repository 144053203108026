// libs
import { Spin } from "antd";

// utils
import { reactionIconsMapper } from "utils";

// styles
import styles from "../styles.module.scss";

export const WolfyReaction = ({ reaction, onClick, count, loading }) => {
  const icon = reactionIconsMapper[reaction];
  return (
    <button onClick={onClick} className={styles.actionContainerReflectItem}>
      {loading ? (
        <Spin />
      ) : (
        <>
          <img src={icon} alt={reaction} />
          <p>{count || 0}</p>
        </>
      )}
    </button>
  );
};
