// libs
import { ethToolsApiAxios } from "libs/AxiosInterceptor";

// constants
import { LIKE_API, TOKEN_TYPES } from "constants";

export const EthToolService = {
  async getTrendngTokens({ token = TOKEN_TYPES.ETH }) {
    return ethToolsApiAxios.get(`${LIKE_API}/${token}/trending`);
  },
  async getTokenTopHolders({ token, tokenAddress }) {
    return ethToolsApiAxios.get(`/${token}/topholders`, {
      headers: {
        "X-Token-Address": tokenAddress,
      },
    });
  },
  async getTokenTopLiquidity({ token, pairAddress, tokenVersion }) {
    return ethToolsApiAxios.get(
      `/${token}/topliquidity?version=${tokenVersion}`,
      {
        headers: {
          "X-Pair-Address": pairAddress,
        },
      }
    );
  },
  async getTokenTransactions({ token, tokenAddress, pairAddress }) {
    return ethToolsApiAxios.get(`/${token}/transactions`, {
      headers: {
        "X-Token-Address": tokenAddress,
        "X-Pair-Address": pairAddress,
      },
    });
  },
  async getTokenInfo({ token, pairAddress }) {
    return ethToolsApiAxios.get(`/${token}/getinfo`, {
      headers: {
        "X-Pair-Address": pairAddress,
      },
    });
  },
  async getTokenSecurity({ token, tokenAddress }) {
    return ethToolsApiAxios.get(`/${token}/security`, {
      headers: {
        "X-Token-Address": tokenAddress,
      },
    });
  },
  async getTokenReactions({ token, tokenAddress }) {
    return ethToolsApiAxios.get(`${LIKE_API}/${token}/reactions`, {
      headers: {
        "X-Token-Address": tokenAddress,
      },
    });
  },
  async getTokenSearch({ token = TOKEN_TYPES.ETH, query, pairAddress }) {
    return ethToolsApiAxios.get(
      `/${token}/search?query=${query}`,
      {},
      {
        headers: {
          "X-Pair-Address": pairAddress,
        },
      }
    );
  },
  async sendTokenLike({ token, likedItem, tokenAddress }) {
    return ethToolsApiAxios.post(
      `${LIKE_API}/${token}/like?item=${likedItem}`,
      {},
      {
        headers: {
          "X-Token-Address": tokenAddress,
        },
      }
    );
  },
};
