export const LOCAL_STORAGE_THEME_KEY = "__ethtools_theme__";

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const THEMES_COLORS = {
  [THEMES.LIGHT]: {
    primary: "#4680fd",
    primaryBg: "#ffffff",
    secondaryBg: "#f7f7f9",
    grayBg: "#ffffff",
    gray: "#6e6e78",
    black: "#111625",
    copy: "#e8ecf3",
    copyLight: "#e8ecf354",
    thBg: "#ffffff",
    modalBg: "#ffffff",
    modalItem: "#f7f7f9",
    notify: "#ffffff",
    actionBtnBorder: "#6e6e782b",
    bitcoinHalving: "#ffffff",
    bitcoinHalvingItem: "#f3f6fb",
  },
  [THEMES.DARK]: {
    primary: "#4680fd",
    primaryBg: "#15171C",
    secondaryBg: "#1E2025",
    grayBg: "#292B31",
    gray: "#7B8093",
    black: "#ffffff",
    copy: "#313337",
    copyLight: "#323546",
    thBg: "#15171C",
    modalBg: "#1E2025",
    modalItem: "#27292E",
    notify: "#27292E",
    actionBtnBorder: "#2E3138",
    bitcoinHalving: "#1E2025",
    bitcoinHalvingItem: "#323338",
  },
};
