// libs
import { Col, Row } from "antd";
import clsx from "classnames";

// components
import { PriceChangeItem } from "components/ActionSidebar";

// constants
import { TOKEN_TYPES } from "constants";

// utils
import { formatEthAddress, formatUSD } from "utils";

// styles
import styles from "./styles.module.scss";

export const Result = ({
  pairAddress,
  baseToken,
  quoteToken,
  price,
  liquidity,
  volume,
  priceChange,
}) => {
  return (
    <a
      href={`/${TOKEN_TYPES.ETH}/${pairAddress}`}
      target="_blank"
      rel="noreferrer noopener"
      className={styles.wrapper}
    >
      <Row align="top" className={styles.wrapperContainer}>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={10}
          className={styles.wrapperContainerPair}
        >
          <p className={styles.wrapperContainerPairInfo}>
            <span className={styles.wrapperContainerPairInfoFirst}>
              {baseToken?.symbol} <span></span>/{quoteToken?.symbol}
            </span>
            <span
              className={clsx(
                "capitalize",
                styles.wrapperContainerPairInfoSecond
              )}
            >
              {baseToken?.name} - <span>{quoteToken?.name}</span>
            </span>
          </p>
          <p className={styles.wrapperContainerPairContent}>
            <span>Pair: {formatEthAddress(pairAddress)}</span>{" "}
            <span className={styles.wrapperContainerPairContentEth}>
              DUB: {formatEthAddress(baseToken?.address)}
            </span>
          </p>
        </Col>
        <Col
          xs={10}
          sm={10}
          md={3}
          lg={3}
          className={styles.wrapperContainerStats}
        >
          <h6>Price</h6>
          <p>{formatUSD(price, 3)}</p>
        </Col>
        <Col
          xs={10}
          sm={10}
          md={3}
          lg={3}
          className={styles.wrapperContainerStats}
        >
          <h6>Liquidity</h6>
          <p>{formatUSD(liquidity)}</p>
        </Col>
        <Col
          xs={10}
          sm={10}
          md={4}
          lg={4}
          className={styles.wrapperContainerStats}
        >
          <h6>Volume, 24h</h6>
          <p>{formatUSD(volume)}</p>
        </Col>
        <Col
          xs={10}
          sm={10}
          md={4}
          lg={4}
          className={styles.wrapperContainerStats}
        >
          <h6>24h</h6>
          <PriceChangeItem item={priceChange} />
        </Col>
      </Row>
    </a>
  );
};
