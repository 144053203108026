// libs
import { createContext, useReducer } from "react";

// constants
import {
  SET_PAIR_ADDRESS,
  RESET_PAIR_ADDRESS,
  SET_TOKEN_ADDRESS,
  SET_INVALID_PAIR,
  TOGGLE_SEARCH_MODAL,
  SET_TOKEN_VERSION,
  SET_HAS_PAIR_ADDRESS_UPDATED,
  RESET_HAS_PAIR_ADDRESS_UPDATED,
} from "constants";

export const EthToolsContext = createContext({
  pairAddress: null,
  tokenAddress: null,
  searchModal: false,
  tokenVersion: null,
  hasPairAddressUpdated: false,
});

export const authReducer = (state, action) => {
  switch (action.type) {
    case SET_PAIR_ADDRESS:
      return { ...state, pairAddress: action.payload };
    case SET_TOKEN_ADDRESS:
      return { ...state, tokenAddress: action.payload };
    case RESET_PAIR_ADDRESS:
      return { ...state, pairAddress: null };
    case TOGGLE_SEARCH_MODAL:
      return { ...state, searchModal: !state.searchModal };
    case SET_TOKEN_VERSION:
      return { ...state, tokenVersion: action.payload };
    case SET_HAS_PAIR_ADDRESS_UPDATED:
      return { ...state, hasPairAddressUpdated: !state.payload };
    case RESET_HAS_PAIR_ADDRESS_UPDATED:
      return { ...state, hasPairAddressUpdated: false };
    case SET_INVALID_PAIR:
      return { ...state, isInValidPair: action.payload };
    default:
      return state;
  }
};

export const EthToolsContextProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, {
    pairAddress: null,
    tokenAddress: null,
    searchModal: false,
    tokenVersion: null,
    hasPairAddressUpdated: false,
    isInValidPair: false,
  });

  return (
    <EthToolsContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </EthToolsContext.Provider>
  );
};
