// libs
import { Col, Modal, Row, Spin } from "antd";

// components
import { SearchInput } from "components/SearchInput";
import { SearchResults } from "components/SearchResults";
import { PreSearchItem } from "./PreSearchItem";

export const SearchModal = ({
  searchTokenResults,
  isSearchTokenLoading,
  isModalOpen,
  showClearText,
  onCancel,
  onSearchInputChange,
  latestUpdatesData,
}) => {
  const renderModalContent = () => {
    if (isSearchTokenLoading) {
      return (
        <div className="loadingContainer">
          <Spin size="large" />
        </div>
      );
    }

    if (searchTokenResults?.pairs) {
      return <SearchResults data={searchTokenResults?.pairs} />;
    }

    if (latestUpdatesData?.length > 0) {
      return (
        <>
          <p className="tokenInfoTitle">Recently updated token info</p>
          <Row gutter={[16, 16]}>
            {latestUpdatesData.map((item) => {
              return (
                <Col lg={6} md={8} sm={12} xs={24} key={item.pairAddress}>
                  <PreSearchItem
                    pairAddress={item?.pairAddress}
                    name={item?.name}
                    img={item?.image_url}
                    website={item?.website_url}
                    telegram={item?.telegram_url}
                    twitter={item?.twitter_url}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      );
    }

    return (
      <p className="tokenInfoTitle">
        No results found for <strong>{showClearText}</strong>
      </p>
    );
  };

  return (
    <Modal
      title={null}
      footer={null}
      closable={false}
      open={isModalOpen}
      onCancel={onCancel}
    >
      <SearchInput
        showClearText={showClearText}
        onChange={onSearchInputChange}
      />
      {renderModalContent()}
    </Modal>
  );
};
