// libs
import { Space } from "antd";

// constants
import { TOKEN_TYPES } from "constants";

// assets
import WebIcon from "assets/Web.svg";
import TwitterIcon from "assets/Twitter.svg";
import TelegramIcon from "assets/Telegram.svg";

// styles
import styles from "./styles.module.scss";

export const PreSearchItem = ({
  pairAddress,
  img,
  name,
  website,
  telegram,
  twitter,
}) => {
  return (
    <a
      href={`/${TOKEN_TYPES.ETH}/${pairAddress}`}
      target="_blank"
      rel="noreferrer noopener"
      className={styles.container}
    >
      {img && <img src={img} alt={name} className={styles.containerImg} />}
      <div className={styles.containerContent}>
        <h2>{name}</h2>
        <Space>
          {website && (
            <a href={website} target="_blank" rel="noreferrer noopener">
              <img src={WebIcon} alt={website} />
            </a>
          )}
          {telegram && (
            <a href={telegram} target="_blank" rel="noreferrer noopener">
              <img src={TelegramIcon} alt={telegram} />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noreferrer noopener">
              <img src={TwitterIcon} alt={twitter} height={14} />
            </a>
          )}
        </Space>
      </div>
    </a>
  );
};
