// assets
import BitcoinSVG from "assets/crypto/Bitcoin.svg";

// styles
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";

export const BitcoinHalving = ({ toggleModal }) => {
  const [blocksUntilHalving, setBlocksUntilHalving] = useState(0);
  const [approximateBlockTime, setApproximateBlockTime] = useState(0);

  const [timeUntilHalving, setTimeUntilHalving] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const currentBlockHeight = 10200;
    const lastBlockTimestamp = 1645712345;

    const blocksPerHalving = 210000;
    const remainingBlocks =
      blocksPerHalving - (currentBlockHeight % blocksPerHalving);
    setBlocksUntilHalving(remainingBlocks);

    const millisecondsPerBlock = 60000 * 9.13;
    const remainingMilliseconds = remainingBlocks * millisecondsPerBlock;
    const days = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (remainingMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);
    setTimeUntilHalving({ days, hours, minutes, seconds });

    const lastBlockTime = new Date(lastBlockTimestamp * 1000);
    const currentBlockTime = new Date();
    const elapsedMilliseconds = currentBlockTime - lastBlockTime;
    const elapsedBlocks = currentBlockHeight % blocksPerHalving;
    const averageBlockTime = elapsedMilliseconds / elapsedBlocks;
    setApproximateBlockTime(averageBlockTime);
  }, []);

  return (
    <>
      <div className={styles.backdrop} onClick={toggleModal} />
      <div className={styles.container}>
        <div className={styles.containerImg}>
          <img src={BitcoinSVG} alt="Bitcoin" />
        </div>
        <div className={styles.containerInfo}>
          <h3>Bitcoin Halving Countdown</h3>
          <p>Estimated halving date is Mon, 15th Apr 2024 10:27:28 UTC</p>
        </div>

        <div className={styles.containerTime}>
          <div className={styles.containerTimeInfo}>
            <h4>{timeUntilHalving.days}</h4>
            <p>Days</p>
          </div>
          <div className={styles.containerTimeInfo}>
            <h4>{timeUntilHalving.hours}</h4>
            <p>Hrs</p>
          </div>
          <div className={styles.containerTimeInfo}>
            <h4>{timeUntilHalving.minutes}</h4>
            <p>Mins</p>
          </div>
          <div className={styles.containerTimeInfo}>
            <h4>{timeUntilHalving.seconds}</h4>
            <p>Secs</p>
          </div>
        </div>

        <div className={styles.containerBlocks}>
          <div className={styles.containerBlocksInfo}>
            <h4>Blocks until halving</h4>
            <p>{blocksUntilHalving}</p>
          </div>
          <div className={styles.containerBlocksInfo}>
            <h4>Approximate block time</h4>
            <p>{approximateBlockTime.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </>
  );
};
