// libs
import { Divider } from "antd";

// utils
import { formatUSD } from "utils";

// styles
import styles from "../styles.module.scss";

export const Item = ({ icon, title, price = 0, address }) => {
  return (
    <a
      href={`https://ethtools.io/eth/${address}`}
      target="_blank"
      rel="noreferrer noopener"
      className={styles.container}
    >
      <div className={styles.item}>
        <div className={styles.itemTitle}>
          {icon && <img src={icon} alt={title} className={styles.itemImg} />}
          <span>{title}</span>
        </div>
        <p>{formatUSD(price, 3)}</p>
      </div>
      <Divider type="vertical" />
    </a>
  );
};
