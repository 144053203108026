// libs
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";

// contexts
import { EthToolsContext } from "contexts/EthToolsContext";

// constants
import { SET_INVALID_PAIR } from "constants";

// assets
import NotFoundIcon from "assets/404.svg";
import RightArrowBlueIcon from "assets/RightArrowBlue.svg";

// styles
import styles from "./styles.module.scss";

export const NotFound = ({
  errorImg = NotFoundIcon,
  errorMsg = "404 ERROR",
  title = "Page not found",
  desc = "Sorry, we couldn't find the page you're looking for.",
  redirectPath = "/",
}) => {
  const { dispatch } = useContext(EthToolsContext);

  const navigate = useNavigate();

  const handleRedirect = () => {
    dispatch({
      type: SET_INVALID_PAIR,
      payload: false,
    });
    navigate(redirectPath, { replace: true });
    window.location.reload(true);
  };

  const renderRedirectButton = () => {
    if (redirectPath === "/") {
      return (
        <Link to="/">
          <Button type="text">
            <span>Go back to Wolfyscore</span>
            <img src={RightArrowBlueIcon} alt="Go back to Wolfyscore" />
          </Button>
        </Link>
      );
    }

    return (
      <Button type="text" onClick={handleRedirect}>
        <span>Go back to Wolfyscore</span>
        <img src={RightArrowBlueIcon} alt="Go back to Wolfyscore" />
      </Button>
    );
  };

  return (
    <section className={styles.container}>
      <img src={errorImg} alt={title} />
      <p className={styles.containerSubTitle}>{errorMsg}</p>
      <h5>{title}</h5>
      <p className={styles.containerDescription}>{desc}</p>
      {renderRedirectButton()}
    </section>
  );
};
