export const debounce = (fn, delay) => {
  let timerId;

  return (...args) => {
    clearTimeout(timerId);
    return new Promise((resolve) => {
      timerId = setTimeout(() => {
        resolve(fn.apply(this, args));
      }, delay);
    });
  };
};
