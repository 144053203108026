// libs
import axios from "axios";

// constants
import { API_TOKEN } from "constants";
import { API } from "constants";

export const ethToolsApiAxios = axios.create({
  baseURL: API,
});

ethToolsApiAxios.interceptors.request.use(addTokenInterceptor);

async function addTokenInterceptor(config) {
  const headers = config.headers || {};
  headers["Authorization"] = `Bearer ${API_TOKEN}`;

  config.headers = headers;
  return config;
}
