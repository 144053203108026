// libs
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from "antd";

// routes
import AppRoutes from "routes";

// contexts
import { EthToolsContextProvider } from "contexts/EthToolsContext";
import { ThemeProvider } from "contexts/ThemeContext";

// styles
import "styles/core.scss";
import "styles/antdStyles.scss";

// react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ThemeProvider>
        <ConfigProvider>
          <EthToolsContextProvider>
            <AppRoutes />
          </EthToolsContextProvider>
        </ConfigProvider>
      </ThemeProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
