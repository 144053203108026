// assets
import ArrowRight from "assets/ArrowRight.svg";
import OpenLink from "assets/OpenLink.svg";
import Checked from "assets/Checked.svg";

// styles
import styles from "./styles.module.scss";

export const WolfyLinks = ({ ethToolsTokenSecurity }) => {
  return (
    <div className={styles.wolfyLinksContainer}>
      {ethToolsTokenSecurity?.gopluslabs_url && (
        <a
          href={ethToolsTokenSecurity?.gopluslabs_url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.wolfyLinksContainerItem}
        >
          <div className={styles.wolfyLinksContainerItemWrapper}>
            <img src={ArrowRight} alt="Go+ Security" />
            <h6>Go+ Security </h6>
          </div>
          <div className={styles.wolfyLinksContainerItemWrapper}>
            <p>No issues</p>
            <img src={Checked} alt="No issues" />
          </div>
        </a>
      )}
      {ethToolsTokenSecurity?.quickintel_url && (
        <a
          href={ethToolsTokenSecurity?.quickintel_url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.wolfyLinksContainerItem}
        >
          <div className={styles.wolfyLinksContainerItemWrapper}>
            <img src={ArrowRight} alt="Quick Intel" />
            <h6>Quick Intel </h6>
          </div>
          <div className={styles.wolfyLinksContainerItemWrapper}>
            <p>No issues</p>
            <img src={Checked} alt="No issues" />
          </div>
        </a>
      )}
      {ethToolsTokenSecurity?.tokensniffer_url && (
        <a
          href={ethToolsTokenSecurity?.tokensniffer_url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.wolfyLinksContainerItem}
        >
          <div
            className={styles.wolfyLinksContainerItemWrapper}
            style={{
              marginLeft: "0.25rem",
              gap: "0.5rem",
            }}
          >
            <img src={OpenLink} alt="Token Sniffer" />
            <h6>Token Sniffer </h6>
          </div>
          <div className={styles.wolfyLinksContainerItemWrapper}>
            <p>100/100</p>
            <img src={Checked} alt="No issues" />
          </div>
        </a>
      )}
    </div>
  );
};
