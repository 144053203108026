// libs
import { useContext, useState } from "react";
import { Button, Input } from "antd";

// contexts
import { EthToolsContext } from "contexts/EthToolsContext";
import { useTheme } from "contexts/ThemeContext";

// components
import { BitcoinHalving } from "components/BitcoinHalving";

// constants
import { TOGGLE_SEARCH_MODAL } from "constants";

// assets
import EthToolsIcon from "assets/EthTools.svg";
import BitcoinSVG from "assets/crypto/Bitcoin.svg";
import DarkThemeSVG from "assets/DarkTheme.svg";
import LightThemeSVG from "assets/LightTheme.svg";

// styles
import styles from "./styles.module.scss";
import { THEMES } from "constants/themes";

const { Search } = Input;

export const Navbar = () => {
  const { theme, toggleTheme } = useTheme();
  const { dispatch } = useContext(EthToolsContext);

  const [isBitcoinHalvingOpen, setIsBitcoinHalvingOpen] = useState(false);

  const handleOnClick = () => {
    dispatch({ type: TOGGLE_SEARCH_MODAL });
  };

  const toggleModal = () => {
    setIsBitcoinHalvingOpen((prev) => !prev);
  };

  return (
    <nav className={styles.navContainer}>
      <img src={EthToolsIcon} alt="Ethtools" />
      <Search
        placeholder="Search pair by symbol, name, contract or token"
        size="large"
        className={styles.navContainerSearch}
        onClick={handleOnClick}
      />

      <Button
        size="large"
        className={styles.navContainerActionBtn}
        icon={<BitcoinIcon />}
        onClick={toggleModal}
      >
        Halving: 65D
      </Button>

      {isBitcoinHalvingOpen && <BitcoinHalving toggleModal={toggleModal} />}

      <Button
        size="large"
        className={styles.navContainerThemeBtn}
        onClick={toggleTheme}
      >
        {theme === THEMES.DARK ? <LightThemeIcon /> : <DarkThemeIcon />}
      </Button>
    </nav>
  );
};

const BitcoinIcon = () => {
  return <img src={BitcoinSVG} alt="Bitcoin" />;
};

const DarkThemeIcon = () => {
  return <img src={DarkThemeSVG} alt="Dark Theme" />;
};

const LightThemeIcon = () => {
  return <img src={LightThemeSVG} alt="Light Theme" />;
};
