import { THEMES_COLORS } from "constants/themes";

export const applyThemeColors = (theme) => {
  const root = document.documentElement;
  root.style.setProperty("--primary", THEMES_COLORS[theme].primary);
  root.style.setProperty("--primaryBg", THEMES_COLORS[theme].primaryBg);
  root.style.setProperty("--secondaryBg", THEMES_COLORS[theme].secondaryBg);
  root.style.setProperty("--grayBg", THEMES_COLORS[theme].grayBg);
  root.style.setProperty("--gray", THEMES_COLORS[theme].gray);
  root.style.setProperty("--black", THEMES_COLORS[theme].black);
  root.style.setProperty("--copy", THEMES_COLORS[theme].copy);
  root.style.setProperty("--copyLight", THEMES_COLORS[theme].copyLight);
  root.style.setProperty("--thBg", THEMES_COLORS[theme].thBg);
  root.style.setProperty("--modalBg", THEMES_COLORS[theme].modalBg);
  root.style.setProperty("--modalItem", THEMES_COLORS[theme].modalItem);
  root.style.setProperty("--notify", THEMES_COLORS[theme].notify);
  root.style.setProperty(
    "--actionBtnBorder",
    THEMES_COLORS[theme].actionBtnBorder
  );
  root.style.setProperty(
    "--bitcoinHalving",
    THEMES_COLORS[theme].bitcoinHalving
  );
  root.style.setProperty(
    "--bitcoinHalvingItem",
    THEMES_COLORS[theme].bitcoinHalvingItem
  );
};
