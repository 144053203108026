// libs
import { Navigate, Route, Routes } from "react-router-dom";

// pages
import { Home } from "pages/Home";
import { NotFound } from "pages/NotFound";

// constants
import { DEFAULT_PAIR_ADDRESS } from "constants";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`eth/${DEFAULT_PAIR_ADDRESS}`} />}
        />
        <Route path="eth/:address" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
