// libs
import { Input } from "antd";

// utils
import { debounce } from "utils/debounce";

// styles
import styles from "./styles.module.scss";

export const SearchInput = ({ onClick = () => {}, onChange = () => {} }) => {
  const debouncedHandleSearchChange = debounce(onChange, 300);

  return (
    <div className={styles.container}>
      <Input
        placeholder="Search pair by symbol, name, contract or token"
        size="large"
        className={styles.containerSearch}
        onClick={onClick}
        onChange={(e) => debouncedHandleSearchChange(e)}
        allowClear
      />
    </div>
  );
};
